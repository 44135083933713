<template>
  <div class="buy-button">
    <div class="buy-button-content">
      <a href="https://www.amazon.de/dp/B0C5793DMF/" target="_blank"><img class="buy-button-image" :src="require('../assets/amazon.png')"/></a>
      </div>
  </div>
</template>

<script>
export default {
  name: "PrimaryButton",
  components: {},
};
</script>

<style lang="scss" scoped>
@import "../scss/colors.scss";

.buy-button-border {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  text-align: center;
  position: relative;
  background-color: $accent-color;
  padding: 2px;
  border-radius: 10px;
}

.buy-button {
  height: 60px;
  background-color: $accent-color;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: $accent-color-dark-shade-1;
  }

  &:active {
    background-color: $accent-color-dark-shade-2;
  }
}

.buy-button-content {
  margin: 8px 8px;
  text-decoration: none;
  font-family: "Dosis", sans-serif;
  font-size: 22px;
  font-weight: 500;
  color: $text-color-primary-light;
  //padding: 0px 8px 0px 8px;
}

.buy-button-image {
  margin-top: 18px;
  max-height: 32px;
}
</style>