<template>
  <div id="team" class="team-section">
    <div class="team-container">
      <div class="team-animation-item team-title-row section-title section-title-dark-bg">Über <span class="highlighted-text">Pleasmile</span></div>
      <div class="team-description-container">
      <div class="team-animation-item description-text">
        Als Zahnarzt habe ich mit meinem Team aus Arzt, Apotheker und Produktdesigner 3 Jahre lang geforscht und unzählige Stunden im Labor verbracht. Unser Ziel war es, für meine Patienten und euch die aus zahnmedizinischer Sicht optimale, gleichzeitig aber auch nachhaltige und gesundheitsbewusste Zahnpasta zu entwickeln.
      </div>
      <br/><br/>
      <div class="team-animation-item description-text">
        Basierend auf einem detaillierten Anforderungsprofil und unter Beachtung der aus unserer Sicht zentralen Säulen „Nutzererfahrung“, „zahnmedizinische Evidenz“ sowie „Nachhaltigkeit &amp; Gesundheit“, entstand letztendlich die feste Zahnpasta in Bestform, unsere Pleasbits.
      </div>
      <br/><br/>
      <div class="team-animation-item description-text">
Pleasmile steht für Zahnpflege mit Sinn und Verstand. Wir wollen das aus zahnmedizinischer Sicht optimale Produkt, welches ein ideales Putzerlebnis liefert und gleichzeitig den Körper und die Natur nicht unnötig mit fragwürdigen oder gar potentiell gesundheitsschädlichen Stoffen belastet. Dafür steht mein Team und ich. Das treibt uns bei Pleasmile an. Überzeugt euch selbst!
      </div>
      </div>
      <div class="mo-container">
        <img class="team-animation-item mo-image" :src="require('../assets/mo.jpg')" />
        <div class="mo-text-container">
          <div class="team-animation-item mo-name description-text">Muhammed Ferit Kiziler</div>
          <div class="team-animation-item mo-profession section-title section-title-dark-bg highlighted-text">Zahnarzt</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "TeamSection",
  components: {},
  mounted: function () {
    ScrollTrigger.defaults({
      markers: false,
    });

    var toothpasteAnimationItems = gsap.utils.toArray(".team-animation-item");
    toothpasteAnimationItems.forEach((toothpasteAnimationItem) => {
      gsap.from(toothpasteAnimationItem, {
        autoAlpha: 0,
        translateY: +100,
        scrollTrigger: {
          trigger: toothpasteAnimationItem,
          start: "top-=100 bottom-=100",
          scrub: true,
          end: "+=250",
        },
      });
    });
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/colors.scss";

.team-section {
  background-color: $section-team-background-color;
  padding: 10vh 20px 10vh 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 120vh;
}

.team-title-row {
  margin: 0px 20px;
}

.team-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0px 20px 0px 20px;
}

.team-description-container {
  margin: 64px 20px;
  max-width: 800px;
}

.mo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
}

.mo-text-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.mo-image {
  width: 100vw;
  max-width: 500px;
}
</style>
