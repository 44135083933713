<template>
  <div id="product-video" class="video-section" data-nosnippet>
    <video ref="videoPlayer" id="videoPlayer" class="video-js vjs-big-play-centered product-video" v-on:click="toggleVideo" playsinline></video>
    <div class="video-play-button" :class="{ playing: playButtonVisible }" v-on:click="toggleVideo">
      <div class="video-play-icon"></div>
    </div>
  </div>
</template>

<script>
import videojs from "video.js";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "ProductVideoSection",
  data: function () {
    return {};
  },
  components: {},
  data() {
    return {
      playButtonVisible: false,
      manuallyPaused: false,
      player: null,
      trigger: null,
      videoOptions: {
        autoplay: true,
        controls: false,
        fluid: false,
        responsive: true,
        muted: true,
        loop: true,
        poster: require("../assets/pleasbits_poster.jpg"),
        sources: [
          {
            src: "https://pleasmile.com/assets/videos/pleasbits_vp9.webm",
            type: "video/webm; codecs=vp9",
          },
          {
            src: "https://pleasmile.com/assets/videos/pleasbits_h265.mp4",
            type: "video/mp4; codecs=hvc1",
          },
          {
            src: "https://pleasmile.com/assets/videos/pleasbits_h264.mp4",
            type: "video/mp4; codecs=avc1",
          },
        ],
      },
    };
  },
  mounted() {
    var self = this;
    this.player = videojs(
      this.$refs.videoPlayer,
      this.videoOptions,
      function onPlayerReady() {
        self.trigger = ScrollTrigger.create({
          trigger: ".product-video",
          //markers: true,
          onEnter() {
            if (self.manuallyPaused == false) {
              if (self.player) {
                self.player.play();
              }
            }
          },
          onLeave() {
            if (self.player) {
              self.player.pause();
            }
          },
          onLeaveBack() {
            if (self.player) {
              self.player.pause();
            }
          },
          onEnterBack() {
            if (self.manuallyPaused == false) {
              if (self.player) {
                self.player.play();
              }
            }
          },
        });
      }
    );
  },
  methods: {
    toggleVideo: function (event) {
      if (event) {
        event.preventDefault();
        if (this.player) {
          if (this.player.paused()) {
            this.playButtonVisible = true;
            this.player.play();
            this.player.muted(false);
            this.manuallyPaused = false;
          } else {
            if (this.playButtonVisible == false && !this.player.paused()) {
              this.playButtonVisible = true;
              this.player.muted(false);
            } else {
              this.playButtonVisible = false;
              this.player.pause();
              this.manuallyPaused = true;
            }
          }
        }
      }
    },
  },
  beforeDestroy() {
    if (this.player) {
      this.trigger.kill();
      this.player.dispose();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/colors.scss";
@import "../../node_modules/video.js/dist/video-js.css";

.video-section {
  height: 120vh;
  width: 100vw;
  background-color: $section-video-background-color;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.video {
  max-height: 100vh;
  max-width: 100vw;
}

.product-video {
  cursor: pointer;
}

.video-play-button {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 240px;
  height: 240px;
  border-radius: 120px;
  background-color: $video-play-button-background-color;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-play-icon {
  width: 100px;
  height: 100px;
  margin-left: 20px;
  background-color: $video-play-icon-background-color;
  -webkit-clip-path: polygon(
    100% 49%,
    100% 49%,
    46% 77%,
    46% 26%,
    46% 25%,
    46% 77%,
    0 100%,
    0 0
  );
  clip-path: polygon(
    100% 49%,
    100% 49%,
    46% 77%,
    46% 26%,
    46% 25%,
    46% 77%,
    0 100%,
    0 0
  );
}

.playing {
  opacity: 0;
}

.product-video {
  height: 100vh;
  width: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-placeholder-text {
  color: $text-color-primary-light;
  font-size: 24px;
  width: 200px;
  text-align: center;
}

video::-webkit-media-controls-panel-container {
  display: none !important;
}
</style>
