<template>
  <div id="ingredients-you-dont-want" class="toothpaste-ingredients">
    <div class="toothpaste-container">
      <div class="toothpaste-item-container">
        <div class="toothpaste-headline">
        <div class="toothpaste-animation-item title-text title-text-big toothpaste-title-row toothpaste-headline">Was Du <span class="title-text-big highlighted-text-negative">nicht</span> in einer</div>
        <div class="toothpaste-animation-item title-text title-text-big toothpaste-title-row">Zahnpasta haben willst</div>
        <div class="toothpaste-animation-item title-text title-text-big toothpaste-title-row">und worauf <span class="toothpaste-animation-item highlighted-text title-text-big">wir verzichten</span></div>
      </div>
        <div class="toothpaste-item">
          <div class="toothpaste-text-container">
            <div class="toothpaste-animation-item title-text highlighted-text-negative">Titandioxid (CI 77891)</div>
            <div class="toothpaste-animation-item description-text description-text-dark">Titandioxid, auch als E 171 in Lebensmitteln oder CI 77891 in Kosmetika bezeichnet, lässt normale Zahnpasta heller und glänzender erscheinen. Seit Anfang Mai 2021 wird Titandioxid durch die Europäische Lebensmittelbehörde als „nicht mehr sicher“ in Lebensmitteln klassifiziert <sup class="superscript">1</sup>. Eine erbgutschädigende und damit potentiell krebserregende Wirkung von Titandioxid kann nicht ausgeschlossen werden. Entsprechend ist der Stoff in Lebensmitteln ab Sommer 2022 verboten <sup class="superscript">2</sup>.
            Weiterhin erlaubt ist Titandioxid allerdings in Kosmetika wie z.B. Zahnpasta. Dies könnte durchaus kritisch sein, da auch hier einerseits geringe Mengen verschluckt werden könnten.<sup class="superscript">3</sup>, aber auch potentiell über die Mundschleimhaut aufgenommen werden.
            Das Bundesinstitut für Risikobewertung (BfR) hat daher bereits die Empfehlung abgegeben, dass der wissenschaftliche Ausschuss für Verbrauchersicherheit der EU (SCCS) prüfen sollte, ob die Neubewertung von Titandioxid in Lebensmitteln auch auf den Kosmetikbereich übertragbar ist <sup class="superscript">3</sup>. 
            </div>
            <div class="source-container">
              <div class="toothpaste-animation-item description-text description-text-dark-bg source-text"><sup class="superscript">1 </sup><a class="external-link" target="_blank" href="https://www.faz.net/aktuell/wirtschaft/unternehmen/warum-titandioxid-in-lebensmitteln-verboten-wird-17363884.html">FAZ.net</a> [abgerufen am 12.11.2021]</div>
              <div class="toothpaste-animation-item description-text description-text-dark-bg source-text"><sup class="superscript">2 </sup><a class="external-link" target="_blank" href="https://www.deutsche-apotheker-zeitung.de/news/artikel/2022/01/19/titandioxid-in-lebensmitteln-ab-august-2022-verboten">Deutsche Apotheker Zeitung</a> [abgerufen am 01.02.2022]</div>
              <div class="toothpaste-animation-item description-text description-text-dark-bg source-text"><sup class="superscript">3 </sup><a class="external-link" target="_blank" href="https://www.test.de/Titandioxid-Wie-sicher-ist-der-Einsatz-in-Lebensmitteln-und-Kosmetika-5794973-0/#question-2">Test.de</a> [abgerufen am 31.01.2022]</div> 
            </div>
          </div>
        </div>
        <div class="toothpaste-item">
          <div class="toothpaste-text-container">
            <div class="toothpaste-animation-item title-text highlighted-text-negative">Parabene</div>
            <div class="toothpaste-animation-item description-text description-text-dark">Parabene können in konventionellen Zahnpasten als Konservierungsmittel eingesetzt werden, dienen also der längeren Haltbarkeit. Ihnen wird eine hormonartige Wirkung bei Aufnahme in den Körper nachgesagt <sup class="superscript">1</sup>.</div>
            <div class="source-container">
              <div class="toothpaste-animation-item description-text description-text-dark-bg source-text"><sup class="superscript">1 </sup><a class="external-link" target="_blank" href="https://ec.europa.eu/health/scientific_committees/docs/citizens_parabens_en.pdf">European Scientific Committee on Consumer Safety</a> [abgerufen am 13.07.2021]</div>
            </div>
          </div>
        </div>
        <div class="toothpaste-item">
          <div class="toothpaste-text-container">
            <div class="toothpaste-animation-item title-text highlighted-text-negative">Natriumlaurylsulfat<br>(SLS, SDS)</div>
            <div class="toothpaste-animation-item description-text description-text-dark">In vielen handelsüblichen Zahnpasten wird Natriumlaurylsulfat (auch als SLS oder SDS bezeichnet) als Schaumbildner eingesetzt. Allerdings steht dieses in der Kritik, da es bei manchen Anwendern Schleimhautreizungen verursachen kann, eine Aphtenbildung möglicherweise fördert und auch für einen gestörten Geschmackssinn nach
dem Zähneputzen verantwortlich gemacht werden kann. <sup class="superscript">1, 2, 3, 4</sup>
</div>
<div class="source-container">
              <div class="toothpaste-animation-item description-text description-text-dark-bg source-text"><sup class="superscript">1 </sup>Herlofson B. B. &amp; Barkvoll P., Sodium lauryl sulfate and recurrent aphthous ulcers. A preliminary study, Acta Odontol Scand. 1994 Oct;52(5):257-9</div>
              <div class="toothpaste-animation-item description-text description-text-dark-bg source-text"><sup class="superscript">2 </sup>Vrani E. et al., Formulation ingredients for toothpastes and mouthwashes, Bosnian Journal of Basic Medical Sciences ,2004,(4):51–58</div>
              <div class="toothpaste-animation-item description-text description-text-dark-bg source-text"><sup class="superscript">3 </sup>Sälzer, S., The effectiveness of dentifrices without and with sodium lauryl sulfate on plaque, gingivitis and gingival abrasion – a randomized clinical trial, Clinical oral investigations, 2016, 20 (3):443–450</div>
              <div class="toothpaste-animation-item description-text description-text-dark-bg source-text"><sup class="superscript">4 </sup>Alli B. Y. et al., Effect of sodium lauryl sulfate on recurrent aphthous stomatitis: A systematic review, J Oral Pathol Med, 2019, 48 (5):358-364</div>
            </div>
          </div>
        </div>
        <div class="toothpaste-item">
          <div class="toothpaste-text-container">
            <div class="toothpaste-animation-item title-text highlighted-text-negative">Triclosan (TCS)</div>
            <div class="toothpaste-animation-item description-text description-text-dark">Triclosan ist ein antimikrobieller Stoff, der in verschiedenen Produkten (wie z.B. in manchen Duschgelen, Seifen und Zahnpasten) eingesetzt wird <sup class="superscript">1</sup>.
Negative Effekte, die diskutiert werden, sind u.a. die Ausbildung von Triclosan- und Antibiotikaresistenzen <sup class="superscript">2, 3, 4, 5</sup> sowie ein erhöhtes Risiko für die Ausbildung von Asthma und Allergien <sup class="superscript">6, 7, 8</sup>.
Aufgrund dessen wurde die Verwendung von Triclosan in der EU 2014 eingeschränkt <sup class="superscript">9</sup>.</div>
            <div class="source-container">
              <div class="toothpaste-animation-item description-text description-text-dark-bg source-text"><sup class="superscript">1 </sup>Weatherly, L. M., &amp; Gosse, J. A. Triclosan exposure, transformation, and human health effects. Journal of toxicology and environmental health. Part B, Critical reviews, 2017, 20(8):447–469 </div>
              <div class="toothpaste-animation-item description-text description-text-dark-bg source-text"><sup class="superscript">2 </sup>Drury B, Scott J, Rosi-Marshall EJ, and Kelly JJ. Triclosan exposure increases triclosan resistance and influences taxonomic composition of benthic bacterial communities. Environ Sci Technol, 2013, 47:8923–8930</div>
              <div class="toothpaste-animation-item description-text description-text-dark-bg source-text"><sup class="superscript">3 </sup>Nietch CT, Quinlan EL, Lazorchak JM, Impellitteri CA, Raikow D, and Walters D. Effects of a chronic lower range of triclosan exposure on a stream mesocosm community. Environ Toxicol Chem, 2013, 32:2874–2887</div>
              <div class="toothpaste-animation-item description-text description-text-dark-bg source-text"><sup class="superscript">4 </sup>Chen Y, Pi B, Zhou H, Yu Y, and Li L. Triclosan resistance in clinical isolates of Acinetobacter baumannii . J Med Microbiol, 2009, 58:1086–1091</div>
              <div class="toothpaste-animation-item description-text description-text-dark-bg source-text"><sup class="superscript">5 </sup>Suller MT, and Russell AD. Triclosan and antibiotic resistance in Staphylococcus aureus. J Antimicrob Chemother, 2000 46:11–18</div>
              <div class="toothpaste-animation-item description-text description-text-dark-bg source-text"><sup class="superscript">6 </sup>Clayton EM, Todd M, Dowd JB, and Aiello AE. The impact of bisphenol A and triclosan on immune parameters in the U.S. population, NHANES 2003–2006. Environ Health Persp, 2011, 119:390–396</div>
              <div class="toothpaste-animation-item description-text description-text-dark-bg source-text"><sup class="superscript">7 </sup>Spanier AJ, Fausnight T, Camacho TF, and Braun JM. The associations of triclosan and paraben exposure with allergen sensitization and wheeze in children. Allergy Asthma Proc, 2014, 35:475–481</div>
              <div class="toothpaste-animation-item description-text description-text-dark-bg source-text"><sup class="superscript">8 </sup>Hong S, Kwon HJ, Choi WJ, Lim WR, Kim J, and Kim K. Association between exposure to antimicrobial household products and allergic symptoms. Environ Health Toxicol, 2014, 29: e2014017</div>
              <div class="toothpaste-animation-item description-text description-text-dark-bg source-text"><sup class="superscript">9 </sup>Usage of triclosan in cosmetic products was restricted by the EU commission in 2014. Commission Regulation (EU) No 358/2014 of 9 April 2014
</div>
            </div>
          </div>
        </div>
        <div class="toothpaste-item">
          <div class="toothpaste-text-container">
            <div class="toothpaste-animation-item title-text highlighted-text-negative">Polyethylenglykole</div>
            <div class="toothpaste-animation-item description-text description-text-dark">Polyethylenglycole, auch als PEGs bezeichnet, fungieren in Zahnpasten (aber auch in einigen anderen Kosmetika) u.a. als Strukturgeber und Emulgatoren. An sich sind sie gut verträglich, allerdings wird eine durch PEGs gesteigerte Durchlässigkeit von Haut und Schleimhaut für andere Stoffe <sup class="superscript">1</sup> als potentiell negative Eigenschaft erachtet <sup class="superscript">2</sup>. Daneben wird in der Literatur ein allergenes Potential beschrieben <sup class="superscript">3</sup>.</div>
            <div class="source-container">
              <div class="toothpaste-animation-item description-text description-text-dark-bg source-text"><sup class="superscript">1 </sup>Sarpotdar, P. P., Gaskill, J. L., &amp; Giannini, R. P. (1986). Effect of polyethylene glycol 400 on the penetration of drugs through human cadaver skin in vitro. Journal of pharmaceutical sciences, 75(1): 26–28</div>
              <div class="toothpaste-animation-item description-text description-text-dark-bg source-text"><sup class="superscript">2 </sup><a class="external-link" target="_blank" href="https://www.oekotest.de/kosmetik-wellness/Zahnpasta-im-Test-Schlechte-Noten-fuer-bekannte-Marken_11784_1.html">Ökotest</a> [abgerufen am 18.12.2021]</div>
              <div class="toothpaste-animation-item description-text description-text-dark-bg source-text"><sup class="superscript">3 </sup>Cox, F., Khalib, K., &amp; Conlon, N. (2021). PEG That Reaction: A Case Series of Allergy to Polyethylene Glycol. Journal of clinical pharmacology, 61(6):832–835</div>
            </div>
          </div>
        </div>
        <div class="toothpaste-item">
          <div class="toothpaste-text-container">
            <div class="toothpaste-animation-item title-text highlighted-text-negative">Kohle</div>
            <div class="toothpaste-animation-item description-text description-text-dark">Kohle wird einigen Zahnpasten zugesetzt, da ihr ein aufhellender Effekt nachgesagt wird. Die Studienlage ist indifferent und nicht überzeugend. Auf der einen Seite konnte kein aufhellender Effekt festgestellt werden <sup class="superscript">1</sup>. Auf der anderen Seite wird basierend auf einer Studie in Verbindung mit einer elektrischen Zahnbürste die erhöhte Abrasivität eher kritisch betrachtet <sup class="superscript">2</sup>.</div>
            <div class="source-container">
              <div class="toothpaste-animation-item description-text description-text-dark-bg source-text"><sup class="superscript">1 </sup>Shaikh, M., Sung, H., Lopez, T., Andra, R., McKean, B., Jesson, J., Pascal, C., Pascal, C., Chavez, A., Schwieterman, K., Oyoyo, U., &amp; Kwon, S. R. 
Effect of charcoal dentifrices on tooth whitening and enamel surface roughness. American journal of dentistry, 2021, 34(6):295–299
</div>
              <div class="toothpaste-animation-item description-text description-text-dark-bg source-text"><sup class="superscript">2 </sup>Greuling, A., Emke, J. M., &amp; Eisenburger, M. Abrasion Behaviour of Different Charcoal Toothpastes When Using Electric Toothbrushes. Dentistry journal, 2021, 9(8):97</div>
            </div>
          </div>
        </div>
        <div class="toothpaste-item">
          <div class="toothpaste-text-container">
            <div class="toothpaste-animation-item title-text highlighted-text-negative">Konservierungsmittel</div>
            <div class="toothpaste-animation-item description-text description-text-dark">Diese dienen der Haltbarkeit von konventioneller Zahnpasta. Das braucht Pleasbits als feste Form natürlich nicht.</div>
          </div>
        </div>
        <div class="toothpaste-item">
          <div class="toothpaste-text-container">
            <div class="toothpaste-animation-item title-text highlighted-text-negative">Feuchthaltemittel</div>
            <div class="toothpaste-animation-item description-text description-text-dark">Diese verhindern das Austrocknen klassischer Zahnpasten. Wieder eine Komponente, die Pleasbits nicht braucht.</div>
          </div>
        </div>
        <div class="toothpaste-item">
          <div class="toothpaste-text-container">
            <div class="toothpaste-animation-item title-text highlighted-text-negative">Süßungsmittel</div>
            <div class="toothpaste-animation-item description-text description-text-dark">Wir haben uns bewusst gegen Süßungsmittel entschieden! Das eingesetzte Aroma verleiht unseren Pleasbits einen angenehm frischen Geschmack und fördert gleichzeitig den Speichelfluss, was wiederrum langfristig gesunden Zähnen zugutekommt.</div>
          </div>
        </div>
        <div class="toothpaste-item">
          <div class="toothpaste-text-container">
            <div class="toothpaste-animation-item title-text highlighted-text-negative">Wasser</div>
            <div class="toothpaste-animation-item description-text description-text-dark">Pleasbits als feste Form enthalten kein eingearbeitetes Wasser und sind damit deutlich ressourcenschonender als konventionelle Zahnpasta.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "ToothpasteSection",
  components: {},
  mounted: function () {
    ScrollTrigger.defaults({
      markers: false,
    });

    var toothpasteAnimationItems = gsap.utils.toArray(
      ".toothpaste-animation-item"
    );
    toothpasteAnimationItems.forEach((toothpasteAnimationItem) => {
      gsap.from(toothpasteAnimationItem, {
        autoAlpha: 0,
        translateY: +100,
        scrollTrigger: {
          trigger: toothpasteAnimationItem,
          start: "top-=100 bottom-=100",
          scrub: true,
          end: "+=250",
        },
      });
    });
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/colors.scss";

.toothpaste-ingredients {
  background-color: $section-toothpaste-ingredients-background-color;
  padding: 160px 8px 160px 8px;
  z-index: 0;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 100vw;
  padding-top: 50vh;
  padding-bottom: 50vh;
}

.toothpaste-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
}

.toothpaste-headline {
  margin-bottom: 128px;
}

.toothpaste-item-container {
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 20px;

  @media only screen and (min-width: 500px) {
    max-width: 720px;
  }

  @media only screen and (min-width: 1000px) {
    padding: 32px 32px;
  }
}

.toothpaste-title-row {
  margin-bottom: 8px;
}

.toothpaste-item {
  display: flex;
  flex-direction: row;
  margin-bottom: 64px;
  width: auto;
}

.toothpaste-text-container {
  padding: 0px 4px 4px 0px;
}
</style>
