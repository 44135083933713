<template>
  <div class="home">
    <NavBar />
    <ProductSection />
    <ProductVideoSection />
    <CustomerRatingsSection />
    <TeamSection />
    <ProductHighlightsSection />
    <DosageSection />
    <UsageSection />
    <EnvironmentalSection />
    <ToothpasteSection />
    <IngredientsSection />
    <FAQSection />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from "@/components/NavBar.vue";
import ProductSection from "@/components/ProductSection.vue";
import ProductHighlightsSection from "@/components/ProductHighlightsSection.vue";
import ProductVideoSection from "@/components/ProductVideoSection.vue";
import CustomerRatingsSection from "@/components/CustomerRatingsSection.vue";
import IngredientsSection from "@/components/IngredientsSection.vue";
import ToothpasteSection from "@/components/ToothpasteSection.vue";
import DosageSection from "@/components/DosageSection.vue";
import UsageSection from "@/components/UsageSection.vue";
import EnvironmentalSection from "@/components/EnvironmentalSection.vue";
import TeamSection from "@/components/TeamSection.vue";
import FAQSection from "@/components/FAQSection.vue";
import Footer from "@/components/Footer.vue";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
export default {
  name: "Home",
  components: {
    NavBar,
    ProductSection,
    ProductVideoSection,
    CustomerRatingsSection,
    TeamSection,
    ProductHighlightsSection,
    IngredientsSection,
    ToothpasteSection,
    DosageSection,
    UsageSection,
    EnvironmentalSection,
    FAQSection,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/colors.scss";

.home {
  margin: 0;
  padding: 0;
  position: relative;
  max-width: 100vw;
}
</style>
