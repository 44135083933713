<template>
  <div id="customer-ratings" class="customer-ratings-section">
    <div class="customer-ratings-container">
        <div class="customer-ratings-title-container">
            <div class="section-title customer-animation-item">Was unsere<br/><span class="highlighted-text">Kunden</span> sagen</div>
            <ReviewItem reviewer="Enzo G." :rating="5" review="Ich habe die Zahnpasta von pleasmile gekauft und das erste mal probiert. Ein tolles Produkt, kein Unterschied zur Zahnpasta, es fühlt sich sogar besser an." reviewSource="Google Rezension"/>
            <ReviewItem reviewer="Hanna P." :rating="5" review="Tabs sind einfach super! Sehr praktisch. Geschmack, Reinigung top. Absolute Empfehlung." reviewSource="Google Rezension"/>
            <ReviewItem reviewer="Musab Y." :rating="5" review="gute Zahnpasta, meine Zähne fühlen sich super an 👌" reviewSource="Google Rezension"/>
            <ReviewItem reviewer="E. und J." :rating="5" review="Super Produkt. Endlich mal eine „Zahncreme“ bei welcher ich keine Reste aus der Tube pressen muss. Der Geschmack ist super und der Schaum ist richtig weich." reviewSource="Google Rezension"/>
        </div>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ReviewItem from "@/components/ReviewItem.vue";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "CustomerRatingsSection",
  data: function () {
    return {};
  },
  components: {
    ReviewItem,
  },
  data() {
    return {};
  },
  mounted: function () {
    ScrollTrigger.defaults({
      markers: false,
    });

    var customerAnimationItems = gsap.utils.toArray(".customer-animation-item");
    customerAnimationItems.forEach((customerAnimationItem) => {
      gsap.from(customerAnimationItem, {
        autoAlpha: 0,
        translateY: +100,
        scrollTrigger: {
          trigger: customerAnimationItem,
          start: "top-=100 bottom-=100",
          scrub: true,
          end: "+=250",
        },
      });
    });
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/colors.scss";

.customer-ratings-section {
  background-color: $section-customer-ratings-background-color;
  color: $text-color-primary-dark;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 10vh 20px 10vh 20px;
}

.customer-ratings-container {
  min-height: 100vh;
  padding-top: 80px;
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>