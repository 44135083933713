import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/terms",
    name: "Terms",
    component: () =>
      import("../components/footer/Terms.vue")
  },
  {
    path: "/returnpolicy",
    name: "ReturnPolicy",
    component: () =>
      import("../components/footer/ReturnPolicy.vue")
  },
  {
    path: "/faq",
    name: "FAQ",
    component: () =>
      import("../components/footer/Questions.vue")
  },
  {
    path: "/imprint",
    name: "Imprint",
    component: () =>
      import("../components/footer/Imprint.vue")
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: () =>
      import("../components/footer/DataPrivacy.vue")
  },
  { 
    path: '/:catchAll(.*)', 
    name: 'NotFound',
    component: () =>
      import("../views/NotFound.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
        return savedPosition;

    }

     if (to.hash) {
        return { selector: to.hash };
    }
return { x: 0, y: 0 }
},
});

export default router;
