<template>
  <div class="review-item">
    <div class="reviewer customer-animation-item">{{ reviewer }}</div>
    <div class="rating customer-animation-item">
    <svg width="20px" height="20px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
        <g id="rating1" transform="matrix(0.0562989,0,0,0.0591329,-3.70407,-0.557349)">
            <path d="M610.23,234.75L462.71,341.93L519.062,515.34C520.933,521.106 518.882,527.426 513.976,530.992C511.519,532.773 508.632,533.668 505.746,533.668C502.859,533.668 499.972,532.777 497.515,530.992L349.995,423.812L202.475,530.992C197.565,534.558 190.924,534.558 186.014,530.992C181.108,527.426 179.057,521.109 180.928,515.34L237.28,341.93L89.76,234.75C84.854,231.184 82.803,224.867 84.674,219.098C86.549,213.329 91.924,209.422 97.99,209.422L280.33,209.422L336.674,36.012C338.549,30.243 343.924,26.336 349.99,26.336C356.056,26.336 361.431,30.243 363.306,36.012L419.65,209.422L601.99,209.422C608.057,209.422 613.431,213.328 615.306,219.098C617.177,224.864 615.127,231.184 610.22,234.75L610.23,234.75Z" style="fill:rgb(255,235,59);fill-rule:nonzero;"/>
        </g>
    </svg>
    <svg width="20px" height="20px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
        <g id="rating2" transform="matrix(0.0562989,0,0,0.0591329,-3.70407,-0.557349)">
            <path d="M610.23,234.75L462.71,341.93L519.062,515.34C520.933,521.106 518.882,527.426 513.976,530.992C511.519,532.773 508.632,533.668 505.746,533.668C502.859,533.668 499.972,532.777 497.515,530.992L349.995,423.812L202.475,530.992C197.565,534.558 190.924,534.558 186.014,530.992C181.108,527.426 179.057,521.109 180.928,515.34L237.28,341.93L89.76,234.75C84.854,231.184 82.803,224.867 84.674,219.098C86.549,213.329 91.924,209.422 97.99,209.422L280.33,209.422L336.674,36.012C338.549,30.243 343.924,26.336 349.99,26.336C356.056,26.336 361.431,30.243 363.306,36.012L419.65,209.422L601.99,209.422C608.057,209.422 613.431,213.328 615.306,219.098C617.177,224.864 615.127,231.184 610.22,234.75L610.23,234.75Z" style="fill:rgb(255,235,59);fill-rule:nonzero;"/>
        </g>
    </svg>
    <svg width="20px" height="20px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
        <g id="rating3" transform="matrix(0.0562989,0,0,0.0591329,-3.70407,-0.557349)">
            <path d="M610.23,234.75L462.71,341.93L519.062,515.34C520.933,521.106 518.882,527.426 513.976,530.992C511.519,532.773 508.632,533.668 505.746,533.668C502.859,533.668 499.972,532.777 497.515,530.992L349.995,423.812L202.475,530.992C197.565,534.558 190.924,534.558 186.014,530.992C181.108,527.426 179.057,521.109 180.928,515.34L237.28,341.93L89.76,234.75C84.854,231.184 82.803,224.867 84.674,219.098C86.549,213.329 91.924,209.422 97.99,209.422L280.33,209.422L336.674,36.012C338.549,30.243 343.924,26.336 349.99,26.336C356.056,26.336 361.431,30.243 363.306,36.012L419.65,209.422L601.99,209.422C608.057,209.422 613.431,213.328 615.306,219.098C617.177,224.864 615.127,231.184 610.22,234.75L610.23,234.75Z" style="fill:rgb(255,235,59);fill-rule:nonzero;"/>
        </g>
    </svg>
    <svg width="20px" height="20px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
        <g id="rating4" transform="matrix(0.0562989,0,0,0.0591329,-3.70407,-0.557349)">
            <path d="M610.23,234.75L462.71,341.93L519.062,515.34C520.933,521.106 518.882,527.426 513.976,530.992C511.519,532.773 508.632,533.668 505.746,533.668C502.859,533.668 499.972,532.777 497.515,530.992L349.995,423.812L202.475,530.992C197.565,534.558 190.924,534.558 186.014,530.992C181.108,527.426 179.057,521.109 180.928,515.34L237.28,341.93L89.76,234.75C84.854,231.184 82.803,224.867 84.674,219.098C86.549,213.329 91.924,209.422 97.99,209.422L280.33,209.422L336.674,36.012C338.549,30.243 343.924,26.336 349.99,26.336C356.056,26.336 361.431,30.243 363.306,36.012L419.65,209.422L601.99,209.422C608.057,209.422 613.431,213.328 615.306,219.098C617.177,224.864 615.127,231.184 610.22,234.75L610.23,234.75Z" style="fill:rgb(255,235,59);fill-rule:nonzero;"/>
        </g>
    </svg>
    <svg width="20px" height="20px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
        <g id="rating5" transform="matrix(0.0562989,0,0,0.0591329,-3.70407,-0.557349)">
            <path d="M610.23,234.75L462.71,341.93L519.062,515.34C520.933,521.106 518.882,527.426 513.976,530.992C511.519,532.773 508.632,533.668 505.746,533.668C502.859,533.668 499.972,532.777 497.515,530.992L349.995,423.812L202.475,530.992C197.565,534.558 190.924,534.558 186.014,530.992C181.108,527.426 179.057,521.109 180.928,515.34L237.28,341.93L89.76,234.75C84.854,231.184 82.803,224.867 84.674,219.098C86.549,213.329 91.924,209.422 97.99,209.422L280.33,209.422L336.674,36.012C338.549,30.243 343.924,26.336 349.99,26.336C356.056,26.336 361.431,30.243 363.306,36.012L419.65,209.422L601.99,209.422C608.057,209.422 613.431,213.328 615.306,219.098C617.177,224.864 615.127,231.184 610.22,234.75L610.23,234.75Z" style="fill:rgb(255,235,59);fill-rule:nonzero;"/>
        </g>
    </svg>
    </div>
    <div class="review customer-animation-item">{{ review }}</div>
    <div class="review-source customer-animation-item">{{ reviewSource }}</div>
  </div>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "ReviewItem",
  props: {
    reviewer: String,
    rating: Number,
    review: String,
    reviewSource: String,
  },
  data: function () {
    return {};
  },
  components: {},
  data() {
    return {};
  },
  mounted: function () {},
};
</script>

<style lang="scss" scoped>
@import "../scss/colors.scss";

.review-item {
  margin: 64px 0px 64px 0px;
}

.reviewer {
  font-family: "Dosis", sans-serif;
  font-size: 22px;
  font-weight: 600;
}

.rating {
  margin-top: 2px;
}

.review {
  font-family: "Dosis", sans-serif;
  font-size: 22px;
  font-weight: 300;
  margin-top: 2px;
}

.review-source {
  font-family: "Dosis", sans-serif;
  font-size: 22px;
  font-weight: 300;
  color: $text-color-tertiary-dark;
  margin-top: 2px;
}
</style>