<template>
    <div id="usage" class="usage">
      <div class="usage-item-switcher-container">
        <div class="usage-item">
          <div class="usage-item-content">
            <div class="title-text title-text-big highlighted-text usage-item-title">Zerkauen</div>
            <div class="description-text description-text-dark">Zerkaue ein Pleasbit mit deinen Zähnen. <br>Tipp: Nutze die Backenzähne.</div>
          </div>
          <div class="usage-item-spacer"></div>
          <img class="usage-item-image" :src="require('../assets/pleasbits_chew_it.gif')"/>
        </div>

        <div class="usage-item">
          <div class="usage-item-content">
            <div class="title-text title-text-big highlighted-text usage-item-title">Putzen</div>
            <div class="description-text description-text-dark">Beginne das Putzen mit einer Zahnbürste an der Stelle mit dem zermahlenen Pleasbit. Der Pleasbit schäumt auf und von nun an fühlt es sich wie eine gewohnte Zahnpasta an.</div>
          </div>
          <div class="usage-item-spacer"></div>
          <img class="usage-item-image" :src="require('../assets/pleasbits_brushing.gif')" />
        </div>
        
        <div class="usage-item">
          <div class="usage-item-content">
            <div class="title-text title-text-dark-bg title-text-big highlighted-text usage-item-title">Fühlen</div>
            <div class="description-text description-text-dark">Spüle den Mund nach dem Putzvorgang aus und fühle, wie schön sauber deine Zähne sind.</div>
          </div>
          <div class="usage-item-spacer"></div>
          <img class="usage-item-image" :src="require('../assets/pleasbits_feel.gif')" />
        </div>
      </div>
    </div>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "UsageSection",
  components: {},
  data: function () {
    return {};
  },
  mounted: function () {
    ScrollTrigger.defaults({
      markers: false,
    });

    var usageItems = gsap.utils.toArray(".usage-item");

    var height = 100 * usageItems.length;

    var tl = gsap.timeline({
      duration: usageItems.length,
      scrollTrigger: {
        trigger: ".usage-item-switcher-container",
        start: "top center",
        end: "+=" + height + "%",
        scrub: true,
        id: "usage-items",
      },
    });

    gsap.timeline({
      scrollTrigger: {
        trigger: ".usage-item-switcher-container",
        start: "top top",
        end: "+=" + height + "%",
        scrub: true,
        pin: ".usage-item-switcher-container",
        pinSpacing: true,
        id: "pinning",
      },
    });

    usageItems.forEach(function (elem, i) {
      gsap.set(elem, { position: "absolute", top: 0 });
      tl.from(elem.querySelector(".usage-item-image"), { autoAlpha: 0 }, i);
      tl.from(
        elem.querySelector(".usage-item-content"),
        { autoAlpha: 0, translateY: 100 },
        i
      );

      if (i != usageItems.length - 1) {
        tl.to(
          elem.querySelector(".usage-item-content"),
          { autoAlpha: 0, translateY: -100 },
          i + 0.75
        );
        tl.to(
          elem.querySelector(".usage-item-image"),
          { autoAlpha: 0 },
          i + 0.75
        );
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/colors.scss";

.usage {
  position: relative;
  background-color: $section-usage-background-color;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.usage-item {
  height: 100vh;
  width: 100%;
  max-width: 1900px;
  padding-top: 60px;
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  margin: auto;

  @media only screen and (min-width: 768px) {
    padding: 2rem;
    padding-top: 80px;
  }

  @media only screen and (min-width: 1000px) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 2rem;
  }
}

.usage-item-switcher-container {
  height: 100vh;
  width: 100%;
  max-width: 1900px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 1000px) {
    flex-direction: row;
  }
}

.usage-item-content {
  overflow: hidden;
  width: 100%;
  height: 400px;
  text-align: left;
  padding: 0px 2rem;
  max-width: 700px;

  @media only screen and (min-width: 768px) {
    width: 80%;
  }

  @media only screen and (min-width: 1200px) {
    width: 40%;
    height: unset;
  }
}

.usage-item-spacer {
  width: 10%;
  height: 10%;
}

.usage-item-title {
  display: inline-block;
  margin-bottom: 16px;
}

.usage-item-image {
  width: 100vw;
  object-fit: contain;
  z-index: 10;
  overflow: hidden;

  @media only screen and (min-width: 900px) {
    width: 100%;
    height: 60%;
  }

  @media only screen and (min-width: 1200px) {
    max-width: 50%;
    max-height: 100%;
  }
}
</style>
