<template>
  <div id="ingredients" class="ingredients">
    <div class="ingredients-container">
      <div class="ingredients-headline">
        <div class="ingredients-animation-item title-text title-text-big ingredients-title-row">Unsere <span class="title-text title-text-big highlighted-text">Inhaltsstoffe</span></div>
        <div class="ingredients-animation-item title-text title-text-small ingredients-title-row ingredients-subtitle-spacer">Ein ideales Produkt ist mehr als die Summe seiner Komponenten. Essentiell ist die perfekte Balance und das dadurch erst mögliche, wirkungsvolle Zusammenspiel der wenigen, mit Bedacht ausgewählten Bestandteile der Pleasbits.</div>
      </div>
      <div class="ingredients-item-container">
          <div class="ingredients-item">
            <div class="ingredients-text-container">
                <div class="ingredients-animation-item title-text highlighted-text">Xylitol</div>
                <div class="ingredients-animation-item description-text description-text-dark-bg">Xylitol (auch Xylit oder Birkenzucker) gehört neben Sorbitol und Mannitol zur Familie der sogenannten Zuckeralkohole (nicht zu verwechseln mit Trinkalkohol!), hat aber im Gegensatz zu den beiden letztgenannten den Vorteil, dass es einen antikariogenen<sup class="superscript-dark-bg"> 1, 2</sup> und damit zahnpflegenden Effekt aufweist. Dieser antikariogene Effekt sorgt für eine Hemmung des Wachstums von Karies-verursachenden Bakterien, wodurch weniger zahnschmelzschädigende Säuren produziert werden. Bei Zahnpasten ist es technisch schwer einen Xylitol-Anteil von über 36% zu erreichen <sup class="superscript"> 2</sup>. Pleasbits weist einen signifikant höheren Anteil an Xylitol auf, wodurch ein erhöhtes Kariesreduktionspotential angestrebt wird. Zudem verfügt Xylitol über einen süßenden, kühlenden und Minzaroma-verstärkenden Effekt, was zusammen mit den antikariogenen Eigenschaften eine ideale Ergänzung der Pleasbits Formulierung darstellt.</div>
                <div class="source-container">
                    <div class="ingredients-animation-item description-text description-text-dark-bg source-text-dark-bg"><sup class="superscript-dark-bg">1 </sup>Pharmazeutische Zeitung, Ausgabe 38/2007, Xylitol - Zuckeraustauschstoff gegen Karies</div>
                    <div class="ingredients-animation-item description-text description-text-dark-bg source-text-dark-bg"><sup class="superscript-dark-bg">2 </sup>Mäkinen, K. K., Der Einsatz von Xylit in der Kariesprophylaxe, 2003, praxisVerlag.de</div>
                </div>
            </div>
          </div>
          <div class="ingredients-item">
            <div class="ingredients-text-container">
            <div class="ingredients-animation-item title-text highlighted-text">Microcrystalline<br>Cellulose</div>
            <div class="ingredients-animation-item description-text description-text-dark-bg">Als Füllstoff für die Pleasbits setzen wir Mikrokristalline Cellulose (MCC) ein, ebenfalls ein Cellulose Derivat (analog zum Gelbildner), gewonnen aus der Cellulose von Pflanzen. MCC wird u.a. in diversen Lebensmitteln als Strukturgeber und Füllstoff eingesetzt. Entsprechend dient MCC in unserer Formulierung ebenfalls als Füllstoff, der eine gute Tablettierbarkeit und mechanische Stabilität der Zahnpastatablette gewährleistet. Außerdem unterstützt er den Reinigungseffekt und dient zusätzlich als Struktur- und Stabilitätsgeber der Pleasbits.</div>
            </div>
          </div>
          <div class="ingredients-item">
            <div class="ingredients-text-container">
            <div class="ingredients-animation-item title-text highlighted-text">Hydrated Silica</div>
            <div class="ingredients-animation-item description-text description-text-dark-bg">Putzkörper — in Zahnpasta oft als Hydrated Silica, Silica oder Kieselsäure bezeichnet — basieren auf natürlich vorkommenden Siliziumdioxiden, die in nahezu allen handelsüblichen Zahnpasten eingesetzt werden. Sie dienen der eigentlichen Reinigung der Zähne, indem sie zusammen mit der Zahnbürste und dem Schaum helfen, Speisereste von der Zahnoberfläche abzulösen. Um mit Pleasbits in der täglichen Anwendung einen möglichst guten Reinigungseffekt zu erzielen, haben wir stärkere Putzkörper mit einer entsprechend höheren Reinigungswirkung ausgewählt, ähnlich einer Whitening Zahnpasta. Durch den daraus resultierenden Reinigungseffekt sollen Verfärbungen verhindert und reduziert werden. Für glatte, helle und glänzende Zähne. </div>
            </div>
          </div>
          <div class="ingredients-item">
            <div class="ingredients-text-container">
            <div class="ingredients-animation-item title-text highlighted-text">Hydroxypropyl<br>Methylcellulose</div>
            <div class="ingredients-animation-item description-text description-text-dark-bg">Bei Kontakt mit Feuchtigkeit im Mundraum quillt Hydroxypropylmethycellulose (HPMC) als Gelbildner auf, sodass zusammen mit dem Schaumbildner ein angenehm cremiger Schaum entsteht. Wir haben während der Entwicklung von Pleasbits großen Wert daraufgelegt, dass der Schaum eine gute Festigkeit aufweist, damit ein angenehmes Putzgefühl schafft sowie praktischer und effektiver ist. Bei dem in Pleasbits eingesetzten Gelbildner handelt es sich um Hydroxypropylmethylcellulose. Dies ist ein Abkömmling (sogenannte Derivate) natürlich vorkommender, in Pflanzen enthaltener Cellulose.</div>
            </div>
          </div>
          <div class="ingredients-item">
            <div class="ingredients-text-container">
            <div class="ingredients-animation-item title-text highlighted-text">Sodium Cocoyl<br>Isethionate</div>
                <div class="ingredients-animation-item description-text description-text-dark-bg">Der Schaumbildner — auch als Tensid bezeichnet — sorgt dafür, dass eine Zahnpasta in Verbindung mit Feuchtigkeit (z.B. aus dem Speichel) aufschäumt. Dabei hilft der Schaum, Speisereste und Beläge durch Benetzung deren Oberflächen während des Putzvorgangs abzulösen. In vielen handelsüblichen Zahnpasten wird Natriumlaurylsulfat (auch als SLS oder SDS bezeichnet) als Schaumbildner eingesetzt. Allerdings steht dieses in der Kritik, da es bei manchen Anwendern Schleimhautreizungen verursachen kann, eine Aphtenbildung möglicherweise fördert und auch für einen gestörten Geschmackssinn nach dem Zähneputzen verantwortlich gemacht werden kann<sup class="superscript-dark-bg"> 1, 2</sup>. Daher enthält Pleasbits kein SLS, sondern das deutlich besser geeignete, sulftatfreie Natriumcocoylisethionat, welches eine optimale Schaumbildung zeigt und somit die Funktionalität der Pleasbits ideal ergänzt.</div>
                <div class="source-container">
                    <div class="ingredients-animation-item description-text description-text-dark-bg source-text-dark-bg"><sup class="superscript-dark-bg">1 </sup>Vrani E. et al., Formulation ingredients for toothpastes and mouthwashes, Bosnian Journal of Basic Medical Sciences (4), 2004, 51–58</div>
                    <div class="ingredients-animation-item description-text description-text-dark-bg source-text-dark-bg"><sup class="superscript-dark-bg">2 </sup>Sälzer, S., The effectiveness of dentifrices without and with sodium lauryl sulfate on plaque, gingivitis and gingival abrasion –a  randomized clinical trial, Clinical oral investigations 20 (3), 2016, 443–450</div>
                </div>
            </div>
          </div>
          <div class="ingredients-item">
            <div class="ingredients-text-container">
            <div class="ingredients-animation-item title-text highlighted-text">Aroma</div>
            <div class="ingredients-animation-item description-text description-text-dark-bg">In Pleasbits setzen wir Minzaroma und Menthol ein, was in der Dreierkombination mit Xylitol für einen angenehm natürlichen und frischen Geschmack sorgt, der einen kühlen Atem verleiht.</div>
            </div>
          </div>
          <div class="ingredients-item">
            <div class="ingredients-text-container">
            <div class="ingredients-animation-item title-text highlighted-text">Cellulose Gum</div>
            <div class="ingredients-animation-item description-text description-text-dark-bg">Hierbei handelt es sich um ein weiteres Cellulose Derivat (neben Microcrystalline Cellulose und Hydroxypropyl Methylcellulose), das die Viskosität des Schaums positiv beeinflusst und so den Pleasbits zu einem tollen Mund- und Putzgefühl verhilft.</div>
            </div>
          </div>
          <div class="ingredients-item">
            <div class="ingredients-text-container">
            <div class="ingredients-animation-item title-text highlighted-text">Magnesium Stearate</div>
            <div class="ingredients-animation-item description-text description-text-dark-bg">Magnesiumstearat ist ein aus natürlich vorkommenden Bestandteilen hergestelltes Salz. Es gehört zu den in der Herstellung von Tabletten am häufigsten eingesetzten Hilfsstoffen. Magnesiumstearat hat unter anderem die Funktion, das Verkleben der Pleasbits Tabletten an Maschinenbauteilen während der Produktion zu verhindern.</div>
            </div>
          </div>
          <div class="ingredients-item">
            <div class="ingredients-text-container">
            <div class="ingredients-animation-item title-text highlighted-text">L-Menthol</div>
            <div class="ingredients-animation-item description-text description-text-dark-bg">In Pleasbits setzen wir u.a. Menthol ein, was in der Dreierkombination mit Xylitol und dem Minzaroma für einen angenehm natürlichen und frischen Geschmack sorgt, der einen kühlen Atem verleiht.</div>
            </div>
          </div>
          <div class="ingredients-item">
            <div class="ingredients-text-container">
                <div class="ingredients-animation-item title-text highlighted-text">Sodium Fluoride</div>
                <div class="ingredients-animation-item description-text description-text-dark-bg">Pleasbits enthalten Natriumfluorid, welches zur Kariesprophylaxe eingesetzt wird. Hierbei kommen verschiedene Effekte zum Tragen. U.a. unterstützt es die Remineralisierung des Zahnschmelzes. Des Weiteren bilden die Fluoride eine Art Schutzbarriere, die neutralisierend auf die entstehenden Säuren wirken. Bakterien, die aus verschiedenen Nahrungsbestandteilen (wie z.B. Zucker) zahnschmelzschädigende Säuren produzieren, haben somit weniger Zeit, den Zahnschmelz anzugreifen. Dadurch sinkt das Kariesrisiko. Tatsächlich kam es seit der Verwendung fluoridhaltiger Zahnpasta zu einem deutlichen Rückgang von Karies bei Kindern und Jugendlichen<sup class="superscript-dark-bg"> 1</sup>. Nicht nur viele Zahnärzte und Wissenschaftler erachten den Fluorideinsatz als sinnvoll, auch „Ökotest“ bewertet den Fluorideinsatz als zwingend notwendig <sup class="superscript-dark-bg"> 2</sup>. Aufgrund der wissenschaftlichen Evidenz<sup class="superscript-dark-bg"> 3</sup> setzt unser Expertenteam auf Fluorid in der Konzentration von 1450 ppm (Parts per Million), was einer typischen und empfohlenen Fluorid Menge in Zahnpasta für Erwachsene entspricht (vgl. <sup class="superscript"> 3</sup>). Durch die Darreichungsform der Zahnpasta als Tablette können wir eine gleichmäßige Zusammensetzung und eine genaue Dosierung des Fluorids erzielen.</div>
                <div class="source-container">
                    <div class="ingredients-animation-item description-text description-text-dark-bg source-text-dark-bg"><sup class="superscript-dark-bg">1 </sup>Splieth, Ch. H. et al., Gruppenprophylaxe nach dem Caries Decline, Oralprophylaxe & Kinderzahnheilkunde 28, 2006, 60–64</div>
                    <div class="ingredients-animation-item description-text description-text-dark-bg source-text-dark-bg"><sup class="superscript-dark-bg">2 </sup><a class="external-link" target="_blank" href="https://www.oekotest.de/kosmetik-wellness/Zahnpasta-TestDie-besten-Zahncremes-mit-und-ohne-Fluorid_111632_1.html">Ökotest</a> [abgerufen am 11.02.2022]</div>
                    <div class="ingredients-animation-item description-text description-text-dark-bg source-text-dark-bg"><sup class="superscript-dark-bg">3 </sup><a class="external-link" target="_blank" href="https://www.cochrane.org/CD007868/ORAL_fluoride-toothpastes-different-strengths-preventing-tooth-decay">Cochrane Library;</a> DOI:10.1002/14651858.CD007868.pub2 [abgerufen am 11.02.2022]</div>
                </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "IngredientsSection",
  components: {},
  mounted: function () {
    ScrollTrigger.defaults({
      markers: false,
    });

    var ingredientsAnimationItems = gsap.utils.toArray(
      ".ingredients-animation-item"
    );
    ingredientsAnimationItems.forEach((ingredientsAnimationItem) => {
      gsap.from(ingredientsAnimationItem, {
        autoAlpha: 0,
        translateY: +100,
        scrollTrigger: {
          trigger: ingredientsAnimationItem,
          start: "top-=100 bottom-=100",
          scrub: true,
          end: "+=250",
        },
      });
    });
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/colors.scss";

.ingredients {
  background-color: $section-pleasbits-ingredients-background-color;
  color: $text-color-primary-light;
  padding: 160px 8px 160px 8px;
  z-index: 0;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 100vw;
  padding-top: 50vh;
  padding-bottom: 50vh;
}

.ingredients-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
  margin-left: 20px;
  margin-right: 20px;
}

.ingredients-headline {
  margin-bottom: 128px;
  margin-left: 20px;
  margin-right: 20px;
}

.ingredients-item-container {
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media only screen and (min-width: 500px) {
    max-width: 720px;
  }

  @media only screen and (min-width: 1000px) {
    margin: 32px 32px;
  }
}

.ingredients-title-row {
  margin-bottom: 8px;
}

.ingredients-subtitle-spacer {
  margin-top: 64px;
  max-width: 680px;
}

.ingredients-item {
  display: flex;
  flex-direction: row;
  margin-bottom: 64px;
  width: auto;
}

.ingredients-text-container {
  padding: 0px 4px 4px 0px;
  margin: 0px 20px;
}
</style>
