<template>
    <div class="product-stage" id="product-highlights">
      <ul class="product-list">
        <li>
          <div class="product-stage-content">
              <div class="section-title product-text"><span class="highlighted-text why-pleasbits-header">Warum Pleasbits?</span></div>
            </div>
        </li>
        <li>
          <div class="product-stage-content">
              <div class="section-title product-text">Basierend auf <br><span class="highlighted-text">wissenschaftlichen</span> Erkenntnissen</div>
            </div>
        </li>
        <li>
          <div class="product-stage-content">
            <div class="section-title product-text">Kein Mikroplastik und keine unnötigen oder potentiell gesundheitsschädigende <span class="highlighted-text">Inhaltsstoffe</span> enthalten</div>
          </div>
        </li>
        <li>
          <div class="product-stage-content">
            <div class="section-title product-text">Xylitol-basierte Formulierung, die effektiv zur <span class="highlighted-text">Kariesprophylaxe</span> beiträgt</div>
          </div>
        </li>
        <li>
          <div class="product-stage-content">
            <div class="section-title product-text">Fluorid in der empfohlenen Menge zum  <span class="highlighted-text">Schutz</span> des Zahnschmelzes</div>
          </div>
        </li>
        <li>
          <div class="product-stage-content">
            <div class="section-title product-text">Cremiger, samtiger <span class="highlighted-text">Schaum</span></div>
          </div>
        </li>
        <li>
          <div class="product-stage-content">
            <div class="section-title product-text">Nutzt die Funktion des körpereigenen Speichels <span class="highlighted-text">synergistisch</span></div>
          </div>
        </li>
        <li>
          <div class="product-stage-content">
            <div class="section-title product-text"><span class="highlighted-text">Einfach</span> in der Anwendung</div>
          </div>
        </li>
        <li>
          <div class="product-stage-content">
            <div class="section-title product-text"><span class="highlighted-text">Perfekt</span> dosiert</div>
          </div>
        </li>
        <li>
          <div class="product-stage-content product-stage-animation-item">
            <div class="section-title product-text"><span class="highlighted-text">Nachhaltig</span></div>
          </div>
        </li>
        <li>
          <div class="product-stage-content">
            <div class="section-title product-text"><span class="highlighted-text">Praktisch</span> auf Reisen</div>
          </div>
        </li>
        <li>
          <div class="product-stage-content">
            <div class="section-title product-text">Entwickelt und hergestellt in <span class="highlighted-text">Deutschland</span></div>
          </div>
        </li>
      </ul>
    </div>

</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "ProductHighlightsSection",
  components: {},
  data: function () {
    return {};
  },
  mounted: function () {
    const targets = document.querySelectorAll(".product-list li");

    gsap.set(".product-list", { autoAlpha: 1 });

    const timeline = gsap.timeline({
      defaults: {
        overwrite: "auto",
        ease: "power1",
      },
      scrollTrigger: {
        end: "+=300%",
        pin: true,
        pinSpacing: true,
        scrub: true,
        start: "top top",
        trigger: ".product-list",
      },
    });

    const stagger = 0.8;
    const duration = 1;

    gsap.set(".product-list", { autoAlpha: 1 });

    timeline.set(targets, { transformOrigin: "center bottom" });

    timeline
      .from(targets, {
        duration: duration,
        opacity: 0,
        rotationX: 0,
        rotationY: 0,
        scale: 1,
        stagger: stagger,
        yPercent: 60,
      })
      .to(
        targets,
        {
          duration: duration,
          opacity: 0,
          rotationX: 20,
          rotationY: 2,
          scale: 0.75,
          stagger: stagger,
          y: -100,
        },
        stagger
      );
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/colors.scss";

.product-stage {
  position: relative;
  background-color: $section-product-highlight-background-color;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  max-width: 100vw;
}

.product-list {
  perspective: 400px;
  visibility: hidden;
  height: 100vh;
  width: 100%;

  li {
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 128px 20px;
  }
}

.product-stage-content {
  overflow: hidden;
  width: auto;
  text-align: center;
  max-width: 800px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.why-pleasbits-header {
  font-size: 56px;

  @media only screen and (max-width: 350px) {
    font-size: 36px;
  }

  @media only screen and (max-width: 480px) {
    font-size: 42px;
  }
}

.product-text {
  font-size: 42px;
  font-weight: 200;
  line-height: 56px;

  @media only screen and (max-width: 350px) {
    font-size: 28px;
    font-weight: 200;
    line-height: 32px;
  }

  @media only screen and (max-width: 480px) {
    font-size: 32px;
    font-weight: 200;
    line-height: 36px;
  }
}
</style>