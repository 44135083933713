<template>
  <div id="app">
    <transition appear @before-enter="beforeEnter" @enter="enter" :css="false">
      <div class="launch-container">
        <div class="brand-container">
          <inline-svg
            :src="require('./assets/pleasmile.svg')"
            width="50vw"
            fill="white"
          ></inline-svg>
        </div>
      </div>
    </transition>
    <router-view />
  </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "App",
  data: function () {
    return {
      trackingAllowed: true,
    };
  },
  components: {
    InlineSvg,
  },
  methods: {
    beforeEnter(el) {
      el.style.opacity = 1;
      el.style.transform = "scale(1,1)";
    },
    enter(el, done) {
      let tl = gsap.timeline();

      tl.to(".brand-container", {
        duration: 2.5,
        delay: 0.5,
        opacity: 1.0,
        ease: "slow(0.7, 0.7, false)",
        onComplete: done,
      });

      tl.to(
        el,
        {
          duration: 0.5,
          opacity: 0,
          scale: 10,
          ease: "power4.in",
          onComplete: done,
        },
        "<1.0"
      );

      tl.to(el, {
        display: "none",
        duration: 0.0,
        onComplete: done,
      });
    },
  },
};
</script>

<style lang="scss">
@import "./scss/colors.scss";

html {
  scroll-snap-type: y mandatory;
}

html,
body,
form {
  margin: 0;
  padding: 0;
  background-color: $page-background-color-primary-dark;
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  font-weight: 300;
}

* {
  box-sizing: border-box;
}

input {
  border-radius: 0;
  -webkit-appearance: none;
}

h2 {
  font-family: Dosis;
  color: $text-color-primary-light;
  font-size: 24px;
  //text-transform: uppercase;
  margin: 0;
  margin-top: 64px;
}

#app {
  font-family: "Dosis", sans-serif;
  font-size: 14px;
  position: relative;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;

  @media (min-width: 576px) {
    height: 100vh;
  }
}

.launch-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.brand-container {
  opacity: 0;
}

.body-noscroll {
  overflow: hidden;
  touch-action: none;
  -webkit-overflow-scrolling: touch;
}

.cookie-overlay {
  position: fixed;
  left: 0;
  top: 0;
  background-color: $overlay-background-color;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
}

.title-text {
  font-family: "Dosis", sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 1.5;

  @media only screen and (min-width: 375px) {
    font-size: 24px;
  }

  @media only screen and (min-width: 410px) {
    font-size: 26px;
  }

  @media only screen and (min-width: 1000px) {
    font-size: 30px;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 36px;
  }

  @media only screen and (min-width: 1600px) {
    font-size: 42px;
  }
}

.title-text-small {
  font-size: 25px;
  line-height: 32px;

  @media only screen and (min-width: 1600px) {
    font-size: 30px;
    line-height: 42px;
  }
}

.title-text-extra-small {
  font-size: 20px;
  line-height: 30px;
}

.title-text-big {
  font-size: 36px;

  @media only screen and (min-width: 375px) {
    font-size: 38px;
  }
}

.highlight-background {
  color: transparent;
  background-size: 100%;
  background-clip: text;
  background-image: linear-gradient(
    to right top,
    #64ffda,
    #5afbd3,
    #4ff7cd,
    #43f3c6,
    #35efbf,
    #35efbf,
    #35efbf,
    #35efbf,
    #43f3c6,
    #4ff7cd,
    #5afbd3,
    #64ffda
  );
}

.description-text {
  color: $text-color-primary-light;
  font-size: 22px;
  margin-top: 6px;
}

.description-text-dark {
  color: $text-color-primary-dark;
}

.plsm-button {
  position: relative;
  margin: auto;
  padding: 4px 16px;
  text-decoration: none;
  font-family: Dosis;
  color: $text-color-primary-light;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $accent-color;
  border-radius: 18px;
  height: 36px;
  cursor: pointer;
  user-select: none;
  transition: box-shadow 0.6s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Dosis", sans-serif;
  font-size: 24px;
  font-weight: 400;

  &:after {
    content: "";
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    border-radius: 30px;
    pointer-events: none;
  }
}

.external-link {
  font-size: 18px;
  font-weight: 400;
  color: $text-color-secondary-dark;
  text-decoration: underline;
  text-align: center;

  &.router-link-exact-hover {
    color: $accent-color;
    text-decoration: underline;
  }

  &:hover {
    color: $accent-color;
    text-decoration: underline;
  }

  &.router-link-exact-active {
    color: $accent-color;
    text-decoration: none;
  }
}

.router-link {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-decoration: none;
}

.highlighted-link {
  color: $accent-color;
}

.superscript {
  color: $text-color-secondary-light;
  font-weight: 600;
}

.superscript-dark-bg {
  color: $text-color-secondary-dark;
  font-weight: 600;
}

.source-text {
  font-size: 18px;
  color: $text-color-secondary-light;
}

.source-text-dark-bg {
  font-size: 18px;
  color: $text-color-secondary-dark;
}

@keyframes animated-gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.section-title {
  font-family: "Dosis", sans-serif;
  font-size: 52px;
  font-weight: 200;
  line-height: 56px;
}

.section-title-dark-bg {
  color: $text-color-primary-light;
}

.highlighted-text {
  background: linear-gradient(60deg, #00d2ff, #0083b0, #00d2ff, #0083b0);
  -webkit-animation: animated-gradient 3s ease infinite;
  animation: animated-gradient 4s ease infinite;
  background-size: 400% 400%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  white-space: nowrap;
}

.highlighted-text-negative {
  background: linear-gradient(60deg, #ff4040, #c22626, #ff4040, #c22626);
  -webkit-animation: animated-gradient 3s ease infinite;
  animation: animated-gradient 4s ease infinite;
  background-size: 400% 400%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  white-space: nowrap;
}

.footer-logo-container {
  max-height: 200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;
}

.footer-brand-image {
  width: 200px;
  height: 87px;
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("assets/pleasmile.svg");

  &:hover {
    background-image: url("assets/pleasmile_grey.svg");
  }
}
</style>
