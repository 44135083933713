<template>
  <div class="faq-item">
    <div class="faq-question faq-animation-item">{{ question }}</div>
    <div class="faq-answer faq-animation-item">{{ answer }} </div>
    <div class="faq-answer faq-animation-item" v-if="link != null">Siehe „<router-link class="highlighted-link faq-link" :to="link">{{ linkTitle }}</router-link>“.</div>
  </div>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "FAQItem",
  props: {
    question: String,
    answer: String,
    link: String,
    linkTitle: String,
  },
  data: function () {
    return {};
  },
  components: {},
  data() {
    return {};
  },
  mounted: function () {},
};
</script>

<style lang="scss" scoped>
@import "../scss/colors.scss";

.faq-item {
  margin: 64px 0px 64px 0px;
}

.faq-question {
  font-family: "Dosis", sans-serif;
  font-size: 22px;
  font-weight: 600;
  color: $text-color-primary-dark;
}

.faq-answer {
  font-family: "Dosis", sans-serif;
  font-size: 22px;
  font-weight: 300;
  margin-top: 2px;
  color: $text-color-primary-dark;
}

.faq-link {
  font-size: 22px;
  text-decoration: underline;

  &.router-link-exact-hover {
    color: $accent-color;
    text-decoration: underline;
  }

  &:hover {
    color: $accent-color;
    text-decoration: underline;
  }

  &.router-link-exact-active {
    color: $accent-color;
    text-decoration: underline;
  }
}
</style>