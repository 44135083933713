<template>
    <div id="environmental" class="environmental">
      <div class="environmental-item-switcher-container">
        <div class="environmental-item">
          <div class="environmental-item-content">
            <div class="title-text title-text-big highlighted-text environmental-item-title">Ressourcenschonend</div>
            <div class="description-text">Die Pleasbits enthalten kein Wasser und kommen zudem ohne Plastiktube aus.</div>
          </div>
          <img class="environmental-item-image" :src="require('../assets/pleasbits_pile.png')"/>
        </div>
        <div class="environmental-item">
          <div class="environmental-item-content">
            <div class="title-text title-text-big highlighted-text environmental-item-title">Nichts bleibt übrig</div>
            <div class="description-text">Im Gegensatz zu Zahnpastatuben verbleiben keine Rückstände in der Verpackung. Jedes Pleasbit kann restlos aufgebraucht werden.</div>
          </div>
          <img class="environmental-item-image" :src="require('../assets/empty.png')"/>
        </div>
        <div class="environmental-item">
          <div class="environmental-item-content">
            <div class="title-text title-text-big highlighted-text environmental-item-title">Einfach zu recyceln</div>
            <div class="description-text">Die Dose kannst du ganz einfach über die gelbe Tonne recyceln oder anders nutzen.</div>
          </div>
          <img class="environmental-item-image environmental-item-image-big" :src="require('../assets/recycling.png')"/>
        </div>
      </div>
    </div>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "EnvironmentalSection",
  components: {},
  data: function () {
    return {};
  },
  mounted: function () {
    ScrollTrigger.defaults({
      markers: false,
    });

    var environmentItems = gsap.utils.toArray(".environmental-item");

    var height = 100 * environmentItems.length;

    gsap.timeline({
      scrollTrigger: {
        trigger: ".environmental-item-switcher-container",
        start: "top top",
        end: "+=" + height + "%",
        scrub: true,
        pin: ".environmental-item-switcher-container",
        pinSpacing: true,
        id: "pinning",
      },
    });

    var tl = gsap.timeline({
      duration: environmentItems.length,
      scrollTrigger: {
        trigger: ".environmental",
        start: "top center",
        end: "+=" + height + "%",
        scrub: true,
        id: "environmental-items",
      },
    });

    environmentItems.forEach(function (elem, i) {
      gsap.set(elem, { position: "absolute", top: 0 });
      tl.from(
        elem.querySelector(".environmental-item-image"),
        { autoAlpha: 0 },
        i
      );
      tl.from(
        elem.querySelector(".environmental-item-content"),
        { autoAlpha: 0, translateY: 100 },
        i
      );

      if (i != environmentItems.length - 1) {
        tl.to(
          elem.querySelector(".environmental-item-content"),
          { autoAlpha: 0, translateY: -100 },
          i + 0.75
        );
        tl.to(
          elem.querySelector(".environmental-item-image"),
          { autoAlpha: 0 },
          i + 0.75
        );
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/colors.scss";

.environmental {
  position: relative;
  background-color: $section-environment-background-color;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 1000px) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.environmental-item-switcher-container {
  height: calc(100vh - 60px);
  width: 100%;
  max-width: 1900px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 768px) {
    height: 100vh;
  }

  @media only screen and (min-width: 1000px) {
    flex-direction: row;
  }
}

.environmental-item {
  height: calc(100vh - 60px);
  width: 100%;
  max-width: 1900px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  padding: 0rem 0rem;
  padding-top: 50px;
  margin: auto;
  color: $text-color-primary-light;

  @media only screen and (min-width: 768px) {
    height: 100vh;
  }

  @media only screen and (min-width: 1000px) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 2rem;
  }
}

.environmental-item-content {
  overflow: hidden;
  width: auto;
  text-align: left;
  padding: 0px 1rem;
  max-width: 700px;
  padding-top: 64px;
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media only screen and (min-width: 768px) {
    width: 80%;
  }

  @media only screen and (min-width: 1000px) {
    width: 50vw;
    text-align: left;
    padding: 0;
    margin: 0px 64px;
  }
}

.environmental-item-title {
  display: inline-block;
  margin-bottom: 16px;
}

.environmental-item-image {
  max-width: 100vw;
  height: auto;
  max-height: 66vw;
  max-width: 500px;
  object-fit: contain;
  z-index: 10;

  @media only screen and (min-width: 900px) {
    width: 100%;
    height: 60%;
  }

  @media only screen and (min-width: 1000px) {
    width: 50%;
    height: auto;
  }
}

.environmental-item-image-big {
  max-width: 700px;
}
</style>
