<template>
  <div class="navbar">
    <div class="navbar-container">
      <div class="content-container">
          <div class="menu-icon" v-on:click="toggleMenu">
            <div class="hamburger" :class="{ open: menuIsActive }">
              <span class="hamburger__top-bun"></span>
              <span class="hamburger__bottom-bun"></span>
            </div>
          </div>
        <router-link class="router-link" to="/" v-on:click.native="closeMenu">
            <div class="brand-image"></div>
        </router-link>
        <a href="https://www.amazon.de/dp/B0C5793DMF/" target="_blank"><div class="cart-icon"></div></a>
      </div>
    </div>
    <div class="overlay-container" :class="{ active: menuIsActive }" v-on:click="closeMenu"></div>
    <div class="menu-container" :class="{ menuActive : menuIsActive }" v-on:click="closeMenu">
      <div class="menu" :class="{ navActive: menuIsActive }">
        <router-link class="router-link" to="/" v-on:click.native="closeMenu">
          <div class="nav-link nav-item-item-2">Über Pleasbits</div>
        </router-link>
        <router-link class="router-link" :to="{ path: '/', hash: '#product-video' }" v-on:click.native="closeMenu">
          <div class="nav-link nav-item-item-3">Produktvideo</div>
        </router-link>
        <router-link class="router-link" :to="{ path: '/', hash: '#customer-ratings' }" v-on:click.native="closeMenu">
          <div class="nav-link nav-item-item-4">Kundenmeinungen</div>
        </router-link>
        <router-link class="router-link" :to="{ path: '/', hash: '#team' }" v-on:click.native="closeMenu">
          <div class="nav-link nav-item-item-5">Über Pleasmile</div>
        </router-link>
        <router-link class="router-link" :to="{ path: '/', hash: '#product-highlights' }" v-on:click.native="closeMenu">
          <div class="nav-link nav-item-item-6">Warum Pleasbits?</div>
        </router-link>
        <router-link class="router-link" :to="{ path: '/', hash: '#dosage' }" v-on:click.native="closeMenu">
          <div class="nav-link nav-item-item-7">Dosierung</div>
        </router-link>
        <router-link class="router-link" :to="{ path: '/', hash: '#usage' }" v-on:click.native="closeMenu">
          <div class="nav-link nav-item-item-8">Anwendung</div>
        </router-link>
        <router-link class="router-link" :to="{ path: '/', hash: '#environmental' }" v-on:click.native="closeMenu">
          <div class="nav-link nav-item-item-9">Umweltaspekte</div>
        </router-link>
        <router-link class="router-link" :to="{ path: '/', hash: '#ingredients-you-dont-want' }" v-on:click.native="closeMenu">
          <div class="nav-link nav-item-item-10">Unnötige Inhaltsstoffe in Zahnpasta</div>
        </router-link>
        <router-link class="router-link" :to="{ path: '/', hash: '#ingredients' }" v-on:click.native="closeMenu">
          <div class="nav-link nav-item-item-11">Unsere Inhaltsstoffe</div>
        </router-link>
        <router-link class="router-link" :to="{ path: '/', hash: '#faq' }" v-on:click.native="closeMenu">
          <div class="nav-link nav-item-item-11">Oft gestellte Fragen</div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "NavBar",
  data: function () {
    return {
      menuIsActive: false,
    };
  },
  components: {},
  methods: {
    toggleMenu: function (event) {
      if (event) {
        event.preventDefault();
        this.menuIsActive = !this.menuIsActive;
        if (this.menuIsActive == true) {
          document.body.classList.add("body-noscroll");
        } else {
          document.body.classList.remove("body-noscroll");
        }
      }
    },
    closeMenu: function (event) {
      if (event) {
        event.preventDefault();
        this.menuIsActive = false;
        document.body.classList.remove("body-noscroll");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/colors.scss";

.navbar {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: fixed;
  width: 100%;
  transition: ease 0.4s;
  z-index: 999;
  left: 0;
  top: 0;
  margin-bottom: 0px;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
    width: 100vw;
    height: 100vh;
  }

  99.999% {
    opacity: 0;
    width: 100vw;
    height: 100vh;
  }

  100% {
    width: 0vw;
    height: 0vh;
  }
}

@keyframes translateX {
  0% {
    opacity: 0;
    transform: translateX(-60px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes translateXBack {
  0% {
    opacity: 1;
    transform: translateX(0px);
  }

  100% {
    opacity: 0;
    transform: translateX(-60px);
  }
}

.navbar-container {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: $navbar-background-color;
  left: 0;
  top: 0;
  backdrop-filter: blur(5px);
}

.content-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100vw;
  height: 60px;
  max-width: 1400px;
  justify-content: space-between;
  padding-left: 5vw;
  padding-right: 5vw;

  @media only screen and (min-width: 1600px) {
    width: 100vw;
  }
}

.overlay-container {
  display: flex;
  z-index: 1000;
  width: 0vw;
  height: 0vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $navbar-overlay-background-color;
  opacity: 0;
  overflow: hidden;
  margin-top: 60px;

  transform-origin: top center;
  animation: fadeout 500ms ease-in-out forwards;
}

.menu-container {
  display: none;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100vw;
  height: 100vh;
  max-width: 1400px;
  padding-left: 5vw;
  padding-right: 5vw;
  z-index: 1000;
}

.menu {
  padding-bottom: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 1001;
  position: absolute;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;

  .nav-item-item-1 {
    transform-origin: top center;
    animation: translateXBack 100ms 100ms ease-in-out forwards;
  }
  .nav-item-item-2 {
    transform-origin: top center;
    animation: translateXBack 100ms 90ms ease-in-out forwards;
  }
  .nav-item-item-3 {
    transform-origin: top center;
    animation: translateXBack 100ms 80ms ease-in-out forwards;
  }
  .nav-item-item-4 {
    transform-origin: top center;
    animation: translateXBack 100ms 70ms ease-in-out forwards;
  }
  .nav-item-item-5 {
    transform-origin: top center;
    animation: translateXBack 100ms 60ms ease-in-out forwards;
  }
  .nav-item-item-6 {
    transform-origin: top center;
    animation: translateXBack 100ms 50ms ease-in-out forwards;
  }
  .nav-item-item-7 {
    transform-origin: top center;
    animation: translateXBack 100ms 40ms ease-in-out forwards;
  }
  .nav-item-item-8 {
    transform-origin: top center;
    animation: translateXBack 100ms 30ms ease-in-out forwards;
  }
  .nav-item-item-9 {
    transform-origin: top center;
    animation: translateXBack 100ms 20ms ease-in-out forwards;
  }
  .nav-item-item-10 {
    transform-origin: top center;
    animation: translateXBack 100ms 10ms ease-in-out forwards;
  }
  .nav-item-item-11 {
    transform-origin: top center;
    animation: translateXBack 100ms 0ms ease-in-out forwards;
  }
}

.active {
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  max-width: 100vw;
  opacity: 1;

  transform-origin: top center;
  animation: fadein 300ms ease-in-out forwards;
}

.menuActive {
  display: flex;
}

.navActive {
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  max-width: 100vw;
  z-index: 1002;

  .nav-item-item-1 {
    transform-origin: top center;
    animation: translateX 200ms 30ms ease-in-out forwards;
  }
  .nav-item-item-2 {
    transform-origin: top center;
    animation: translateX 200ms 60ms ease-in-out forwards;
  }
  .nav-item-item-3 {
    transform-origin: top center;
    animation: translateX 200ms 90ms ease-in-out forwards;
  }
  .nav-item-item-4 {
    transform-origin: top center;
    animation: translateX 200ms 120ms ease-in-out forwards;
  }
  .nav-item-item-5 {
    transform-origin: top center;
    animation: translateX 200ms 150ms ease-in-out forwards;
  }
  .nav-item-item-6 {
    transform-origin: top center;
    animation: translateX 200ms 180ms ease-in-out forwards;
  }
  .nav-item-item-7 {
    transform-origin: top center;
    animation: translateX 200ms 210ms ease-in-out forwards;
  }
  .nav-item-item-8 {
    transform-origin: top center;
    animation: translateX 200ms 240ms ease-in-out forwards;
  }
  .nav-item-item-9 {
    transform-origin: top center;
    animation: translateX 200ms 270ms ease-in-out forwards;
  }
  .nav-item-item-10 {
    transform-origin: top center;
    animation: translateX 200ms 300ms ease-in-out forwards;
  }
  .nav-item-item-11 {
    transform-origin: top center;
    animation: translateX 200ms 330ms ease-in-out forwards;
  }
}

.brand-image {
  height: 48px;
  width: 107px;
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../assets/pleasmile.svg");

  &:hover {
    background-image: url("../assets/pleasmile_grey.svg");
  }
}

.menu-icon {
  position: relative;
  width: 24px;
  height: 48px;
  cursor: pointer;
  z-index: 1002;
  position: flex;
  justify-content: center;
  align-items: center;
}

.cart-icon {
  width: 24px;
  height: 30px;
  margin-right: 12px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../assets/cart.svg");
  cursor: pointer;

  &:hover {
    background-image: url("../assets/cart_grey.svg");
  }
}

.buy-button-container {
  position: unset;
}

.router-link {
  text-decoration: none;
  padding: 0;
}

.nav-link {
  font-size: 18px;
  font-weight: 400;
  color: $text-color-primary-light;
  text-decoration: none;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: 44px;
  max-height: 100vh;
  border-radius: 4px;
  background-color: $navbar-background-color;
  backdrop-filter: blur(5px);
  padding: 10px 16px;
  margin-top: 4px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  opacity: 0;

  &.router-link-exact-hover {
    background: $accent-color-dark-shade-1;
    text-decoration: none;
  }

  &:hover {
    background: $accent-color-dark-shade-1;
    text-decoration: none;
  }

  &.router-link-exact-active {
    background: $accent-color-dark-shade-2;
    text-decoration: none;
  }
}

.nav-link-primary {
  background: $navbar-background-highlight-color;
}

.hamburger {
  cursor: pointer;
  position: absolute;
  width: 48px;
  height: 48px;
  transition: all 0.25s;
}

.hamburger__top-bun,
.hamburger__bottom-bun {
  content: "";
  display: block;
  position: absolute;
  left: 15px;
  width: 18px;
  height: 1px;
  background: $navbar-link-color;
  transform: rotate(0);
  transition: all 0.25s;
}

.hamburger:hover [class*="-bun"] {
  background: $navbar-link-hover-color;
}

.hamburger__top-bun {
  top: 23px;
  transform: translateY(-3px);
}

.hamburger__bottom-bun {
  bottom: 23px;
  transform: translateY(3px);
}

.open {
  transform: rotate(90deg);
}

.open .hamburger__top-bun {
  transform: rotate(45deg) translateY(0px);
}

.open .hamburger__bottom-bun {
  transform: rotate(-45deg) translateY(0px);
}
</style>
