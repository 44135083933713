<template>
  <div class="footer">
    <div class="footer-container">
      <router-link class="router-link external-link" to="/terms">AGB</router-link>
      <router-link class="router-link external-link" to="/privacy">Datenschutz</router-link>
      <router-link class="router-link external-link" to="/imprint">Impressum</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  components: {},
  data: function () {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/colors.scss";

.footer {
  background-color: $page-background-color-primary-dark;
  z-index: 0;
  margin-top: -1px;
  width: 100vw;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer-container {
  max-width: 100vw;
  padding: 0vh 5vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.router-link {
  padding-left: 8px;
  padding-right: 8px;
  height: 32px;

  @media (min-width: 500px) {
    margin-left: 8px;
    margin-right: 8px;
  }
}
</style>
