<template>
    <div id="dosage" class="dosage">
      <div class="dosage-item-switcher-container">
        <div class="dosage-item">
          <div class="dosage-item-content">
            <div class="title-text title-text-big dosage-item-title">Zu wenig?</div>
          </div>
          <img class="dosage-item-image" :src="require('../assets/dosage1.jpg')"/>
        </div>
        <div class="dosage-item">
          <div class="dosage-item-content">
            <div class="title-text title-text-big dosage-item-title">Zu viel?</div>
          </div>
          <img class="dosage-item-image" :src="require('../assets/dosage2.jpg')" />
        </div>
        <div class="dosage-item">
          <div class="dosage-item-content">
            <div class="title-text title-text-big dosage-item-title highlighted-text">Immer <br>genau richtig!</div>
          </div>
          <img class="dosage-item-image" :src="require('../assets/dosage3.jpg')" />
        </div>
      </div>
    </div>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "DosageSection",
  components: {},
  data: function () {
    return {};
  },
  mounted: function () {
    ScrollTrigger.defaults({
      markers: false,
    });

    var dosageItems = gsap.utils.toArray(".dosage-item");

    var height = 100 * dosageItems.length;

    var tl = gsap.timeline({
      duration: dosageItems.length,
      scrollTrigger: {
        trigger: ".dosage",
        start: "top center",
        end: "+=" + height + "%",
        scrub: true,
        id: "dosage-items",
      },
    });

    gsap.timeline({
      scrollTrigger: {
        trigger: ".dosage-item-switcher-container",
        start: "top top",
        end: "+=" + height + "%",
        scrub: true,
        pin: ".dosage-item-switcher-container",
        pinSpacing: true,
        id: "pinning",
      },
    });

    dosageItems.forEach(function (elem, i) {
      gsap.set(elem, { position: "absolute", top: 0 });
      tl.from(elem.querySelector(".dosage-item-image"), { autoAlpha: 0 }, i);
      tl.from(
        elem.querySelector(".dosage-item-content"),
        { autoAlpha: 0, translateY: 100 },
        i
      );

      if (i != dosageItems.length - 1) {
        tl.to(
          elem.querySelector(".dosage-item-content"),
          { autoAlpha: 0, translateY: -100 },
          i + 0.75
        );
        tl.to(
          elem.querySelector(".dosage-item-image"),
          { autoAlpha: 0 },
          i + 0.75
        );
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/colors.scss";

.dosage {
  position: relative;
  background-color: $section-dosage-background-color;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 1000px) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.dosage-item-switcher-container {
  height: 100vh;
  width: 100%;
  max-width: 1900px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 1000px) {
    flex-direction: row;
  }
}

.dosage-item {
  height: 100vh;
  max-width: 1900px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  margin: auto;
  color: $text-color-primary-light;

  @media only screen and (min-width: 1000px) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 2rem;
  }
}

.dosage-item-content {
  overflow: hidden;
  width: auto;
  padding-top: 64px;

  @media only screen and (min-width: 768px) {
    max-width: 80%;
    padding-top: 0px;
  }

  @media only screen and (min-width: 1000px) {
    max-width: 30%;
    text-align: right;
  }
}

.dosage-item-title {
  display: inline-block;
  text-align: left;
}

.dosage-item-image {
  width: auto;
  max-width: 100%;
  object-fit: contain;
  z-index: 10;

  @media only screen and (min-width: 900px) {
    width: 100%;
    height: 60%;
  }

  @media only screen and (min-width: 1000px) {
    width: 50%;
    height: auto;
  }
}
</style>
