<template>
  <section id="#product" class="product-section">
        <div class="product-container">
            <div class="pleasbits-container-wide">
                <img class="pleasbits-image-wide" :src="require('../assets/pleasbits_open_1024.png')"/>
            </div>
            <div class="pleasbits-container">
                <div class="pleasbits-header-container">
              <div class="pleasbits-brand">pleasbits</div>
              <div class="pleasbits-slogan">Zahnpasta<br/>in Bestform</div>
              </div>
              <div class="pleasbits-image-container">
                  <img class="pleasbits-image" :src="require('../assets/pleasbits_open_1024.png')"/>
              </div>
              <div class="pleasbits-description-container">
                  <div class="pleasbits-description-highlight">Die feste Zahnpasta 2.0,<br>entwickelt von Zahnarzt und Apotheker.</div>
                  <div class="pleasbits-description">Die Komplettlösung mit hoher Reinigungswirkung* für die tägliche Zahnpflege. Zahnmedizinisch sinnvolle, gesunde und nachhaltige Zahnpflege - mit der Extra-Power an Xylitol für optimalen Kariesschutz - für dein strahlendes Lächeln mit gutem Gewissen!</div>
                  <div class="pleasbits-hint-container">
                   <div class="pleasbits-hint">*</div> 
                  <div class="pleasbits-hint">vergleichbar einer Whitening Zahnpasta.<br>Nicht empfohlen bei sensiblen Zähnen.</div>
                  </div>
                  <div class="buy-button-container">
                    <PrimaryButton />
                  </div>
              </div>
            </div>
        </div>
        <div class="scroll-down-container">
          <div class="chevron"></div>
        </div>
      </div>
  </section>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import PrimaryButton from "@/components/PrimaryButton.vue";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "ProductSection",
  data: function () {
    return {};
  },
  components: {
    PrimaryButton,
  },
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "../scss/colors.scss";

.product-section {
  background-color: $section-product-background-color;
  color: $text-color-primary-dark;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
}

.product-container {
  min-height: 100vh;
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width: 1000px) {
    max-width: 1100px;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
}

.pleasbits-header-container {
  padding: 0px 20px 0px 20px;

  @media only screen and (min-width: 1000px) {
    padding: 0px 0px 20px 0px;
  }
}

.pleasbits-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;

  @media only screen and (min-width: 1000px) {
    align-items: flex-start;
    margin: 0px 32px 0px 32px;
  }
}

.pleasbits-container-wide {
  display: none;

  @media only screen and (min-width: 1000px) {
    display: block;
  }
}

.pleasbits-brand {
  font-family: "Rajdhani", sans-serif;
  font-size: 42px;
  font-weight: 600;
}

.pleasbits-slogan {
  font-family: "Dosis", sans-serif;
  font-size: 52px;
  font-weight: 200;
  line-height: 52px;

  background: linear-gradient(60deg, #00d2ff, #0083b0, #00d2ff, #0083b0);
  -webkit-animation: animated-gradient 3s ease infinite;
  animation: animated-gradient 3s ease infinite;
  background-size: 400% 400%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pleasbits-image-container {
  overflow: hidden;
  max-width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 8px;

  @media only screen and (max-width: 999px) {
    margin-top: 32px;
    margin-bottom: 32px;
  }
}

.pleasbits-image {
  width: 110vw;
  margin-top: 24px;
  margin-bottom: 24px;
  max-width: 600px;

  @media only screen and (min-width: 540px) {
    width: 100%;
    object-fit: contain;
  }

  @media only screen and (min-width: 1000px) {
    display: none;
  }
}

.pleasbits-image-wide {
  width: 100%;
  object-fit: contain;
  padding: 0px 20px 48px 20px;
  max-width: 600px;
}

.pleasbits-description-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0px 20px 0px 20px;

  @media only screen and (min-width: 1000px) {
    margin: 20px 0px 20px 0px;
  }
}

.pleasbits-description-highlight {
  font-family: "Dosis", sans-serif;
  font-size: 20px;
  font-weight: 500;
  max-width: 350px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 8px;
}

.pleasbits-description {
  font-family: "Dosis", sans-serif;
  font-size: 20px;
  font-weight: 300;
  max-width: 350px;
}

.pleasbits-hint-container {
  display: flex;
  flex-direction: row;
  margin: 8px 0px;
}

.pleasbits-hint {
  font-size: 14px;
  color: $text-color-secondary-light;
}

.buy-button-container {
  width: 100%;
  max-width: 350px;
  padding: 24px 0px 64px 0px;
  @media only screen and (min-width: 1000px) {
    padding: 24px 0px 24px 0px;
  }
}

.scroll-down-container {
  display: none;
  width: 24px;
  height: 24px;
  animation: bounce 1s ease infinite;
  position: absolute;
  z-index: 10;
  top: 99vh;

  @media only screen and (min-width: 1000px) {
    display: block;
    top: 90vh;
  }
}

.chevron {
  position: absolute;
  width: 28px;
  height: 8px;
  opacity: 1;
}

@keyframes bounce {
  50% {
    transform: translateY(-50%);
  }
}

.chevron:before,
.chevron:after {
  content: " ";
  position: absolute;
  top: 0;
  height: 100%;
  width: 51%;
  background: #000;
}

.chevron:before {
  left: 0;
  transform: skew(0deg, 30deg);
}

.chevron:after {
  right: 0;
  width: 50%;
  transform: skew(0deg, -30deg);
}
</style>