<template>
  <div id="faq" class="faq-section">
    <div class="faq-container">
        <div class="faq-title-container">
            <div class="section-title faq-animation-item">Oft gestellte <span class="highlighted-text">Fragen</span></div>
            <FAQItem question="Enthalten Pleasbits Fluorid und wenn ja wieso?" answer="Ja, Pleasbits enthalten Natriumfluorid als Fluoridträger. Fluorid ist erwiesenermaßen essenziell für den Schutz des Zahnschmelzes. Es ist nicht zu verwechseln mit dem giftigen Gas Fluor!" link="/#ingredients" linkTitle="Unsere Inhaltsstoffe"/>
            <FAQItem question="Sind die Pleasbits für Kinder geeignet?" answer="Pleasbits sind für Erwachsene und Kinder ab 12 Jahren geeignet."/>
            <FAQItem question="Wie gut reinigen die Pleasbits?" answer="Pleasbits bieten durch ihre sorgfältig ausgewählten Putzkörper eine höhere Reinigungswirkung. Die stärkeren Putzkörper weisen eine Abrasivität im höheren Bereich auf, ähnlich einer Whitening Zahnpasta. Damit sollen Verfärbungen durch alltägliche Einflüsse, wie z.B. Nahrungsmittel, reduziert und verhindert werden. Für glatte, helle und strahlende Zähne." link="/#ingredients" linkTitle="Unsere Inhaltsstoffe"/>
            <FAQItem question="Benötige ich für die Pleasbits eine Zahnbürste?" answer="Ja, so wie du es von normaler Zahnpasta kennst."/>
            <FAQItem question="Wieviele Pleasbits sollte ich pro Putzvorgang verwenden?" answer="Pro Putzvorgang solltest du ein Pleasbit verwenden." link="/#dosage" linkTitle="Dosierung"/>
            <FAQItem question="Wie wende ich die Pleasbits korrekt an?" answer="Nimm ein Pleasbit in den Mund, zerkaue es gut und vermenge es mit dem Speichel. Beginne dann mit einer angefeuchteten Zahnbürste zu putzen, wie du es gewohnt bist. Spüle anschließend deinen Mund aus." link="/#usage" linkTitle="Anwendung"/>
            <FAQItem question="Was muss ich bei der Aufbewahrung der Pleasbits beachten?" answer="Die Pleasbits sollten dicht verschlossen und trocken in ihrer Dose aufbewahrt werden. Zudem sollten die Pleasbits nicht zu warm (z.B. direkt neben einer Heizung) gelagert werden, damit das optimale Aroma erhalten bleibt."/>
            <FAQItem question="Wie lange sind die Pleasbits haltbar?" answer="Nach Anbruch sollten die Pleasbits innerhalb von drei Monaten aufgebraucht werden."/>
            <FAQItem question="Wie entsorge ich die Pleasbits Dose?" answer="Die Pleasbits Dose kannst du in der gelben Tonne entsorgen."/>
        </div>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import FAQItem from "@/components/FAQItem.vue";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "FAQSection",
  data: function () {
    return {};
  },
  components: {
    FAQItem,
  },
  data() {
    return {};
  },
  mounted: function () {
    ScrollTrigger.defaults({
      markers: false,
    });

    var faqAnimationItems = gsap.utils.toArray(".faq-animation-item");
    faqAnimationItems.forEach((faqAnimationItem) => {
      gsap.from(faqAnimationItem, {
        autoAlpha: 0,
        translateY: +100,
        scrollTrigger: {
          trigger: faqAnimationItem,
          start: "top-=100 bottom-=100",
          scrub: true,
          end: "+=250",
        },
      });
    });
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/colors.scss";

.faq-section {
  background-color: $section-customer-ratings-background-color;
  color: $text-color-primary-dark;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 10vh 20px 10vh 20px;
}

.faq-container {
  min-height: 100vh;
  padding-top: 80px;
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>